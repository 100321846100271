import axios from "axios";
import cookie from "js-cookie";

import {
  API_HOST,
  API_HOST_TEST,
  COVER_ENTERTAINMENT,
  COVER_GAMES,
  COVER_INSURANCE,
  COVER_BETS,
  COVER_LOSOLIVOS,
  COVER_RECHARGE,
  COVER_SERVICES,
  CELLPHONE_ICON,
  PACKAGES_ICON,
  ENTERTAIMENT_ICON,
  BETS_ICON,
  GAMES_ICON,
  SERVICES_ICON,
  INSURANCE_ICON,
  LOSOLIVOS_ICON,
  CELLPHONE_ICON_NEW,
  PACKAGES_ICON_NEW,
  ENTERTAINMENT_ICON_NEW,
  BETS_ICON_NEW,
  GAMES_ICON_NEW,
  SERVICES_ICON_NEW,
  INSURANCE_ICON_NEW,
  LOSOLIVOS_ICON_NEW,
  SOAT_ICON_NEW,
  PROD_MODE,
  PAYMENTMANAGER_API_HOST,
  TV_ICON
} from "../lib/envariables";

const ENVIROMENT = PROD_MODE.toUpperCase() == "TRUE" ? API_HOST : API_HOST_TEST;

let token = cookie.get("token");
let tokenApi = cookie.get("tokenApi");

const types = [
  {
    code: "2",
    description: "Recargas Celular",
    imageUrl: CELLPHONE_ICON,
    coverImage: COVER_RECHARGE,
  },
  {
    code: "7",
    description: "Paquetes Celular",
    imageUrl: PACKAGES_ICON,
    coverImage: COVER_RECHARGE,
  },
  {
    code: "18",
    description: "TV",
    imageUrl: TV_ICON,
    coverImage: COVER_ENTERTAINMENT,
  },
  {
    code: "15",
    description: "Entretenimiento",
    imageUrl: ENTERTAIMENT_ICON,
    coverImage: COVER_ENTERTAINMENT,
  },
  {
    code: "13",
    description: "Juegos",
    imageUrl: GAMES_ICON,
    coverImage: COVER_GAMES,
  },
  {
    code: "14",
    description: "Servicios",
    imageUrl: SERVICES_ICON,
    coverImage: COVER_SERVICES,
  },
  {
    code: "10",
    description: "Seguros",
    imageUrl: INSURANCE_ICON,
    coverImage: COVER_INSURANCE,
  },
  {
    code: "17",
    description: "Exequiales",
    imageUrl: LOSOLIVOS_ICON,
    coverImage: COVER_LOSOLIVOS,
  },
  /*{
   code: "16",
    description: "Apuestas",
    imageUrl: BETS_ICON,
    coverImage: COVER_BETS,
  },*/
];

const typesNew = [
  {
    code: "2",
    description: "Recargas Celular",
    imageUrl: CELLPHONE_ICON_NEW,
    coverImage: COVER_RECHARGE,
  },
  /*{
    code: "7",
    description: "Paquetes Celular",
    imageUrl: PACKAGES_ICON_NEW,
    coverImage: COVER_RECHARGE,
  },*/
  {
    code: "15",
    description: "Entretenimiento",
    imageUrl: ENTERTAINMENT_ICON_NEW,
    coverImage: COVER_ENTERTAINMENT,
  },
  {
    code: "13",
    description: "Juegos",
    imageUrl: GAMES_ICON_NEW,
    coverImage: COVER_GAMES,
  },
  {
    code: "14",
    description: "Servicios",
    imageUrl: SERVICES_ICON_NEW,
    coverImage: COVER_SERVICES,
  },
  {
    code: "10",
    description: "Seguros",
    imageUrl: INSURANCE_ICON_NEW,
    coverImage: COVER_INSURANCE,
  },
  {
    code: "17",
    description: "Exequiales",
    imageUrl: LOSOLIVOS_ICON_NEW,
    coverImage: COVER_LOSOLIVOS,
  },
  {
    code: "18",
    description: "TV",
    imageUrl: TV_ICON,
    coverImage: COVER_ENTERTAINMENT,
  },
  // {
  //   code: "16",
  //   description: "Apuestas Deportivas",
  //   imageUrl: BETS_ICON,
  //   coverImage: COVER_BETS,
  // },
];

export const guestAuthentication = async () => {
  let ua = navigator.userAgent;
  let browser;
  let os;
  let version;

  // Determina el navegador
  if (ua.indexOf("Chrome") !== -1) {
    browser = "Chrome";
  } else if (ua.indexOf("Firefox") !== -1) {
    browser = "Firefox";
  } else if (ua.indexOf("Safari") !== -1) {
    browser = "Safari";
  } else if (ua.indexOf("Trident") !== -1) {
    browser = "Internet Explorer";
  }

  // Determina el sistema operativo y su versión
  if (ua.indexOf("Windows") !== -1) {
    os = "Windows";
    if (ua.indexOf("Windows NT 11.0") !== -1) {
      version = "11";
    } else if (ua.indexOf("Windows NT 10.0") !== -1) {
      version = "10";
    } else if (ua.indexOf("Windows NT 6.2") !== -1) {
      version = "8";
    } else if (ua.indexOf("Windows NT 6.1") !== -1) {
      version = "7";
    }
  } else if (ua.indexOf("Mac") !== -1) {
    os = "macOS";
    if (ua.indexOf("Mac OS X 10.15") !== -1) {
      version = "Catalina";
    } else if (ua.indexOf("Mac OS X 10.14") !== -1) {
      version = "Mojave";
    } else if (ua.indexOf("Mac OS X 10.13") !== -1) {
      version = "High Sierra";
    }
    // Agrega más condiciones para versiones anteriores de macOS aquí
  } else if (ua.indexOf("Linux") !== -1) {
    os = "Linux";
  } else if (ua.indexOf("Android") !== -1) {
    os = "Android";
    version = ua.slice(ua.indexOf("Android") + 8);
  } else if (ua.indexOf("iOS") !== -1) {
    os = "iOS";
    version = ua.slice(ua.indexOf("iOS") + 4);
  }
  const body = {
    deviceId: "6740d7365465bb5c",
    appVersion: "2.7.2",
    deviceMake: browser,
    deviceModel: os,
    deviceOsVersion: version,
    deviceType: "2",
    deviceTime: new Date().toString(),
    latitude: "0",
    longitude: "0",
  };
  try {
    const resp = await axios.post(`${ENVIROMENT}/guest/signIn`, body, {
      headers: {
        language: "es",
        "Content-Type": "application/json",
      },
    });
    token = resp.data.data.token;
    cookie.set("token", token, {
      path: "/",
    });
  } catch (error) {
    console.error(error, error.stack);
    console.log(error.response.data);
  }
};

export const isAuthenticated = () => {
  return token ? true : false;
};

export const getType = async (code) => {
  try {
    const type = types.find((element) => element.code == code);
    return type;
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getTypes = async () => {
  try {
    return types;
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getTypesNew = async () => {
  try {
    return typesNew;
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getSubTypes = async (type) => {
  try {
    const response = await axios.get(`${ENVIROMENT}/digitalProduct/types`, {
      headers: {
        Authorization: `${token}`,
        language: "es",
      },
    });
    const types = response.data.data;
    const typeRetrived = types.find((element) => element.code == type);
    const subtypes = typeRetrived.subtypes;
    return subtypes;
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getSubTypesFullSeguro = async (type) => {
  try {
    const response = await axios.get(`${ENVIROMENT}/digitalProduct/types`, {
      headers: {
        Authorization: `${token}`,
        language: "es",
      },
    });
    const types = response.data.data;
    const typeRetrived = types.find((element) => element.code == type);
    const subtypes = typeRetrived.subtypes;

    if (type == 10) {
      // Delete Segurisimo
      const removeIndex = subtypes.findIndex( item => item.code === 81 );
      // remove object
      subtypes.splice( removeIndex, 1 );
    }
    
    return subtypes;
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getProducts = async (type, subType) => {
  try {
    const { data: products } = await axios.get(
      `${ENVIROMENT}/digitalProduct/${type}/${subType}?enabled=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          language: "es",
        },
      }
    );
    return products.data;
  } catch (error) {
    console.error(error, error.stack);
  }
};


export const getCertificadoVehicular = async () => {
  try {
    const { data: certificado } = await axios.get(
      `${ENVIROMENT}/digitalProduct/certificadoVehicular`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          language: "es",
        },
      }
    );
    const response = {
      "message": "Certificado obtenido exitosamente",
      "success": true,
      "data": [
        {
          "code": 62,
          "description": certificado.data.descTransaccion,
          "imageUrl": "https://fullmovil-public.s3.us-west-2.amazonaws.com/imagenes/web/runt.png",
          "type": 14,
          "typeMsg": "Servicios",
          "subtype": "62",
          "subtypemsg": certificado.data.descTransaccion,
          "value": certificado.data.valor,
          "seqId": 0
        },
      ]
    }
    return response.data;
    
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getCertificadoTradicion = async () => {
  try {
    const { data: certificado } = await axios.get(
      `${ENVIROMENT}/digitalProduct/certificadoTradicion`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          language: "es",
        },
      }
    );
    const response = {
      "message": "Certificado obtenido exitosamente",
      "success": true,
      "data": [
        {
          "code": 39,
          "description": certificado.data.descTransaccion,
          "imageUrl": "https://fullmovil-public.s3.us-west-2.amazonaws.com/imagenes/web/snr.png",
          "type": 14,
          "typeMsg": "Servicios",
          "subtype": "39",
          "subtypemsg": certificado.data.descTransaccion,
          "value": certificado.data.valor,
          "seqId": 0
        },
      ]
    }
    return response.data;
    
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const getFacturasExequiales = async (tipoDocumento, numeroDocumento) => {
  try {
    const { data: facturas } = await axios.get(
      `${ENVIROMENT}/digitalProduct/facturasExequiales/${tipoDocumento}/${numeroDocumento}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          language: "es",
        },
      }
    );
    return facturas.data;
  } catch (error) {
    console.error(error, error.stack);
  }
};


export const getOperatorInfo = async (subType) => {
  try {
    const { data: products } = await axios.get(
      `${ENVIROMENT}/digitalProduct/operator/${subType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          language: "es",
        },
      }
    );
    return products.data;
  } catch (error) {
    console.error(error, error.stack);
  }
};

export const createOrder = async (body) => {
  try {
    const resp = await axios.post(`${ENVIROMENT}/digitalProductOrder`, body, {
      headers: {
        Authorization: token,
        language: "es",
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    console.error(error, error.stack);
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export const validatePayment = async (body) => {
  try {
    const resp = await axios.post(
      `${ENVIROMENT}/digitalProductOrder/validate`,
      body,
      {
        headers: {
          Authorization: token,
          language: "es",
          "Content-Type": "application/json",
        },
      }
    );
    return resp.data;
  } catch (error) {
    console.error(error, error.stack);
    if (error.response) {
      return error.response.data;
    } else {
      return;
    }
  }
};

export const getPseBanks = async () => {
  try {
    const { data: response } = await axios.get(
      `${ENVIROMENT}/paymentMethods/pse/banks`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          language: "es",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error, error.stack);
    console.log(error);
    return error.response.data;
  }
};

export const getPaymentMethods = async () => {
  try {
    const { data: response } = await axios.get(`${ENVIROMENT}/paymentMethods`, {
      headers: {
        Authorization: `Bearer ${token}`,
        language: "es",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error, error.stack);
    console.log(error);
    return error.response.data;
  }
};


export const getIntegritySecurity = async ({ paymentReference, productValue, currency }) => {
  try {
    const response = await axios.get(`${PAYMENTMANAGER_API_HOST}/utils/wompi/v1/integrity-signature/${paymentReference}/${productValue}/${currency}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.resultIntegrity;
  } catch (error) {
    // console.error(error, error.stack);
    console.log(error);
    return error;
  }
};


export const tycLosOlivos = async (txid) => {
  try {
    const response = await axios.get(`${PAYMENTMANAGER_API_HOST}/utils/losolivos/v1/tyc/${txid}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.message;
  } catch (error) {
    // console.error(error, error.stack);
    console.log(error);
    return error;
  }
};
